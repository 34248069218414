export default {
    sentry: {
        enabled: process.env.VUE_APP_SENTRY_ENABLED === "true",
        projectId: process.env.VUE_APP_SENTRY_PROJECT_ID,
        key: process.env.VUE_APP_SENTRY_KEY,
        dsn: process.env.VUE_APP_SENTRY_DSN || ".ingest.sentry.io",
    },
    API: {
        gutenbergOneRobot: {
            baseURL: process.env.VUE_APP_GUTENBERG_ONE_ROBOT_API_URL,
            basicAuth: {
                username: process.env.VUE_APP_GUTENBERG_ONE_ROBOT_API_BASIC_USERNAME,
                password: process.env.VUE_APP_GUTENBERG_ONE_ROBOT_API_BASIC_PASSWORD,
            },
        },
    },
    app: {
        showPrice: process.env.VUE_APP_SHOW_PRICE,
        logo: process.env.VUE_APP_LOGO,
    },
    bookBoxes: {
        smallBookBox: { max: process.env.VUE_APP_SMALLBOOKBOX_MAX },
        largeBookBox: { max: process.env.VUE_APP_LARGEBOOKBOX_MAX },
        canBePrintWhenBookBoxesDoorIsUnlocked: process.env.VUE_APP_CAN_BE_PRINT_WHEN_BOOKBOX_DOOR_IS_UNLOCKED === "true",

    },
};